import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  DateField,
  MultiSelect,
  NumberField,
  Option,
  SelectField,
  TextField,
  Text,
  Tooltip,
} from "wm-ui-toolkit";
import { CheckmarkIcon } from "wm-graphics";
import { LEAD_FORM_NEW_LEAD } from "../../../constants/endpoints";
import GlobalBanner from "../../../controls/GlobalBanner/GlobalBanner";
import wingmateLogo from "../../../assets/wingmate-with-name.png";
import "./LeadFormPresenter.scss";

export class LeadFormPresenter extends React.Component {
  static propTypes = {
    leadForm: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      status: PropTypes.string,
      questions: PropTypes.arrayOf(PropTypes.object),
      redirectTo: PropTypes.string,
      poweredByVisible: PropTypes.bool,
    }),
    campaign: PropTypes.shape({
      id: PropTypes.number,
      customFields: PropTypes.arrayOf(PropTypes.object),
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      lead: {
        customFields: {},
      },
      errorBannerOpen: false,
      requiredCustomFieldsLabel: this.findRequiredCustomFieldsLabel(),
      submit: false,
    };
  }

  handleCustomFieldChange = (newValue) => {
    const { lead } = this.state;

    let { value } = newValue;
    if (value.constructor === Object) {
      value = value.value;
    }

    lead.customFields[newValue.id] = value;

    this.setState({ lead });
  };

  handleFieldChange = (newValue) => {
    const { lead } = this.state;

    let { value } = newValue;
    if (value.constructor === Object) {
      value = value.value;
    } else if (Array.isArray(value)) {
      value = value.map((item) => item.value);
    }

    lead[newValue.id] = value;

    this.setState({ lead });
  };

  onSubmit = async () => {
    const { lead } = this.state;
    const { leadForm } = this.props;
    const emptyRequiredCustomFields = this.findEmptyRequiredCustomFields();

    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    if (emptyRequiredCustomFields.length === 0) {
      try {
        await axios.post(
          LEAD_FORM_NEW_LEAD(leadForm.id),
          {
            lead: {
              address: lead.address,
              name: lead.name,
              custom_fields: lead.customFields,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrf,
              Accept: "application/json",
            },
          }
        );

        if (leadForm.redirectTo) {
          const currentURL = window.location.href;

          let rest;
          if (currentURL.includes("?")) {
            rest = `?${currentURL.slice(currentURL.indexOf("?") + 1)}`;
          } else {
            rest = "";
          }

          let hrefLink;
          if (!leadForm.redirectTo.includes("https://")) {
            hrefLink = `//${leadForm.redirectTo}`;
          } else {
            hrefLink = leadForm.redirectTo;
          }

          setTimeout(() => {
            window.location.href = `${hrefLink}${rest}`;
          });
        } else {
          this.setState({ submit: true });
        }
      } catch (err) {
        this.openErrorBanner();
      }
    }
  };

  openErrorBanner = () => {
    this.setState({ errorBannerOpen: true });
  };

  onKeyDownSubmit = (event) => {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  };

  findRequiredCustomFieldsLabel = () => {
    const { leadForm } = this.props;
    const { questions } = leadForm;

    const requiredCustomFields = questions.filter((question) => {
      if (question.required) {
        return question;
      }
      return null;
    });
    const requiredCustomFieldsLabel = requiredCustomFields.map(
      (question) => question.customField
    );

    return requiredCustomFieldsLabel;
  };

  findEmptyRequiredCustomFields = () => {
    const { lead, requiredCustomFieldsLabel } = this.state;

    const emptyRequiredCustomFields = requiredCustomFieldsLabel.filter(
      (customField) => {
        if (lead.customFields[customField] === undefined) {
          return true;
        }
        if (lead.customFields[customField] === (null || "" || [])) {
          return true;
        }
        return false;
      }
    );

    return emptyRequiredCustomFields;
  };

  renderOptions = (options) => {
    return options.map((option) => {
      return (
        <Option value={option} key={option}>
          {option}
        </Option>
      );
    });
  };

  renderInputForCustomFieldQuestion(customField) {
    const { name, type } = customField;

    const inputProps = {
      id: customField.id,
      onChange: this.handleCustomFieldChange,
      placeholder: name,
    };

    switch (type) {
      case "text":
        return <TextField {...inputProps} />;
      case "number":
        return <NumberField {...inputProps} />;
      case "select":
        return (
          <SelectField {...inputProps}>
            {this.renderOptions(customField.options)}
          </SelectField>
        );
      case "multiselect":
        return (
          <MultiSelect {...inputProps} requireSelect>
            {this.renderOptions(customField.options)}
          </MultiSelect>
        );
      case "date":
        return <DateField {...inputProps} />;
      case "datetime":
        return <DateField {...inputProps} />;
      default:
        return <TextField {...inputProps} />;
    }
  }

  renderInputForQuestion(field) {
    const { id, name } = field;

    const inputProps = {
      id,
      onChange: this.handleFieldChange,
      placeholder: name,
    };

    return <TextField {...inputProps} />;
  }

  renderQuestions = () => {
    const { campaign, leadForm } = this.props;

    const { customFields } = campaign;

    return leadForm.questions.map((question) => {
      if (question.customField) {
        const customFieldForQuestion = customFields.find(
          (field) => field.id === question.customField
        );

        return (
          <div
            key={question.customField}
            className="lead-form__question-wrapper"
          >
            <div className="lead-form__question-label">
              <h3>{question.text}</h3>
              {question.required && (
                <Tooltip
                  content="This field must not be empty"
                  direction="right"
                >
                  <div className="asterisk">*</div>
                </Tooltip>
              )}
            </div>
            {this.renderInputForCustomFieldQuestion(customFieldForQuestion)}
          </div>
        );
      }
      return (
        <div key={question.customField} className="lead-form__question-wrapper">
          <div className="lead-form__question-label">
            <h3>{question.text}</h3>
          </div>
          {this.renderInputForQuestion({
            name: question.name,
            type: "text",
            id: question.field,
          })}
        </div>
      );
    });
  };

  onCloseErrorBanner = () => {
    this.setState({ errorBannerOpen: false });
  };

  render() {
    const { leadForm } = this.props;
    const { errorBannerOpen, submit } = this.state;

    return (
      <div className="lead-form-wrapper" data-testid="LeadFormWrapper">
        {errorBannerOpen && (
          <GlobalBanner
            onClose={this.onCloseErrorBanner}
            text="this form can not be submitted"
            className="red"
          />
        )}
        <div className="lead-form-container">
          {submit ? (
            <div className="submit-animation">
              <CheckmarkIcon />
              <Text type="H3">We will be in touch with you soon!</Text>
            </div>
          ) : (
            <div className="lead-form-content">
              <div className="lead-form__questions">
                {this.renderQuestions()}
              </div>
              <div className="lead-form__footer">
                <div
                  role="button"
                  tabIndex="0"
                  className="lead-form__button"
                  onClick={this.onSubmit}
                  onKeyDown={this.onKeyDownSubmit}
                >
                  Submit
                </div>
                {leadForm.poweredByVisible && (
                  <div className="lead-form__wingmate-logo-wrapper">
                    <div className="lead-form__wingmate-logo">
                      <h4>Powered by</h4>
                      <img src={wingmateLogo} alt="Wingmate" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LeadFormPresenter;
