import React from "react";
import { Text } from "@wingmate/toolkit";
import PropTypes from "prop-types";
import { LeadListItem } from "./LeadListItem";
import "./LeadGroup.scss";

export function LeadGroup({ data, labels }) {
  const getLabel = (status) => {
    const statusObject = labels.find((node) => node.status === status);
    return statusObject.id;
  };

  return (
    <div className="LeadGroup">
      <Text className="LeadGroup__Title" type="H4">
        {`${getLabel(data[0].previous_status)} > ${getLabel(data[0].status)}`}
      </Text>
      <ul className="LeadGroup__List">
        {data.map((lead) => (
          <LeadListItem
            key={lead.id}
            id={lead.id}
            name={lead.name}
            city={lead.city}
            address={lead.address}
            date={lead.actioned_at}
            health={lead.health}
            imgSrc={lead.image_url || lead.thumbnail}
            owners={lead.owners}
            status={getLabel(lead.status)}
            statusColor={lead.status_color}
            origin={lead.origin_url}
          />
        ))}
      </ul>
    </div>
  );
}
export default LeadGroup;

LeadGroup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
    })
  ),
};
