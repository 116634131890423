import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dashboard as ToolkitDashboard } from "@wingmate/graphs";
import { axiosGet } from "../../requests/axiosRequests";
import {
  encodeDictionaryToUrlParams,
  removeUndefinedValues,
} from "../../utils/commons";
import { urlEncodeParams } from "../../utils/url";
import { Filters } from "./Filters/Filters";
import { ClosedWonAndClosedLostCell } from "./cells/ClosedWonAndClosedLostCell";
import { TimeInEachStage } from "./cells/TimeInEachStage";
import { StatusChangeCell } from "./cells/StatusChangeCell";
import { LeadsGeneratedCell } from "./cells/LeadsGeneratedCell";
import { StatusCell } from "./cells/StatusCell";
import { LeaderboardCell } from "./cells/LeaderboardCell";
import "./Dashboard.scss";

const SECTION_IDS = [
  "closed",
  "failed",
  "leads",
  "status",
  "leaderboard",
  "changes",
  "stages",
];

const ANALYTICS_ENDPOINT = (sectionId, filters) =>
  `crushr/analytics/${sectionId}.json?${filters}`;

export function Dashboard({ campaigns, defaultFilters }) {
  const [analytics, setAnalytics] = useState({});
  const [filters, setFilters] = useState({
    interval: "week",
    startDate: undefined,
    endDate: undefined,
    ...defaultFilters,
  });
  const [isLoading, setIsLoading] = useState(true);

  const setBrowserUrl = (params) => {
    if (window.history.replaceState) {
      const encodedParams = urlEncodeParams(params);

      const newUrl = `${window.location.pathname}?${encodedParams}`;
      window.history.pushState("", null, newUrl);
    }
  };

  const getAnalytics = async (params) => {
    setIsLoading(true);

    const cleanedParams = removeUndefinedValues(params);

    setBrowserUrl(cleanedParams);

    const payload = {
      interval: params.interval,
      campaign_ids: params.campaignIds,
      regions: params.regions,
      start_date: params.startDate,
      end_date: params.endDate,
    };

    const cleanedPayload = removeUndefinedValues(payload);
    const encodedPayload = encodeDictionaryToUrlParams(cleanedPayload);

    const newAnalytics = { ...analytics };

    await Promise.all(
      SECTION_IDS.map(async (sectionId) => {
        const response = await axiosGet(
          ANALYTICS_ENDPOINT(sectionId, encodedPayload)
        );

        newAnalytics[sectionId] = response.data[sectionId];

        setAnalytics(newAnalytics);
      })
    );

    setIsLoading(false);
  };

  const onFilterChange = (changedFilters) => {
    const newFilters = {
      ...filters,
      ...changedFilters,
    };

    setFilters(newFilters);
    getAnalytics(newFilters);
  };

  useEffect(() => {
    getAnalytics(filters);
  }, []);

  return (
    <ToolkitDashboard
      title="Crushr"
      className="CrushrDashboard"
      subtitle="Performance"
      iconColor="#FFFFFF"
      columnCount={4}
      rowCount={2}
      icon={
        <img
          src="https://gopherleads.s3.us-west-2.amazonaws.com/crushrLogo.png"
          className="crushr"
          alt="Crushr"
        />
      }
      filters={
        <Filters
          campaigns={campaigns}
          defaultFilters={filters}
          isLoading={isLoading}
          onSubmit={onFilterChange}
        />
      }
    >
      <LeadsGeneratedCell leads={analytics.leads} />
      <ClosedWonAndClosedLostCell
        closed={analytics.closed}
        failed={analytics.failed}
        filters={filters}
      />
      <TimeInEachStage stages={analytics.stages} />
      <StatusChangeCell changes={analytics.changes} filters={filters} />
      <StatusCell status={analytics.status} filters={filters} />
      <LeaderboardCell leaderboard={analytics.leaderboard} />
    </ToolkitDashboard>
  );
}

Dashboard.propTypes = {
  campaigns: PropTypes.array,
  defaultFilters: PropTypes.object,
};

export default Dashboard;
