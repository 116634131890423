import axios from "axios";

axios.defaults.xsrfCookieName = "CSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.withCredentials = true;

axios.interceptors?.response?.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { status } = error.response;
      const currentPath = window.location.pathname;

      if ([500].includes(status) && currentPath !== "/error") {
        window.location = `/error`;
      }
    }
    return Promise.reject(error);
  }
);

export async function axiosGet(endpoint, params = {}, additionalHeaders = {}) {
  const response = await axios.get(endpoint, {
    params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    },
  });

  return {
    headers: response.headers,
    data: response.data,
    status: response.status,
  };
}

export async function axiosPost(endpoint, payload, additionalHeaders = {}) {
  const response = await axios.post(endpoint, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    },
  });

  return response;
}

export async function axiosPut(endpoint, payload, additionalHeaders = {}) {
  const response = await axios.put(endpoint, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    },
  });

  return response;
}

export async function axiosDelete(endpoint, payload, additionalHeaders = {}) {
  const response = await axios.delete(endpoint, {
    data: payload,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    },
  });

  return response;
}
