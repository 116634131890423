import React from "react";
import PropTypes from "prop-types";
import { GroupForm } from "../GroupForm/GroupForm";
import { postGroup } from "../../../../requests/companies/groups";

export function NewGroupForm({ company, groups, users }) {
  const submitForm = async (group) => {
    await postGroup(company.id, group);

    window.location.href = `/admin/companies/${company.id}/groups`;
  };

  return (
    <div>
      <GroupForm users={users} groups={groups} onSubmit={submitForm} />
    </div>
  );
}

NewGroupForm.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number,
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      attributes: PropTypes.shape({
        fullName: PropTypes.string,
      }),
    })
  ),
};

export default NewGroupForm;
