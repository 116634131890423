import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tooltip } from "wm-ui-toolkit";
import { Icon } from "../Icon/Icon";
import "./Button.scss";

const ENTER_KEY = "Enter";

export class Button extends React.Component {
  static defaultProps = {
    round: false,
    outOfDom: false,
  };

  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    tooltipMessage: PropTypes.string,
    round: PropTypes.bool,
    type: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    outOfDom: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.fieldRef = React.createRef();
  }

  onClick = () => {
    const { onClick } = this.props;

    if (onClick) {
      onClick();
    }
  };

  onKeyDownHandler = (event) => {
    const { onClick } = this.props;
    if (onClick && event.key === ENTER_KEY) {
      onClick();
    }
  };

  renderButton = () => {
    const { className, icon, round, text, type } = this.props;
    const buttonClass = classNames("react-button", { round }, type, className);

    return (
      <div
        className={buttonClass}
        onClick={this.onClick}
        onKeyDown={this.onKeyDownHandler}
        role="button"
        tabIndex={0}
        data-testid="Button"
      >
        {text}
        {icon && (
          <Icon data-testid="ButtonIcon" iconName={icon} className={icon} />
        )}
      </div>
    );
  };

  render() {
    const { outOfDom, tooltipMessage } = this.props;
    const ref = outOfDom ? this.fieldRef : null;
    return (
      <div className="react-button" ref={ref}>
        {tooltipMessage ? (
          <Tooltip data-testid="ButtonTooltip" content={tooltipMessage}>
            {this.renderButton()}
          </Tooltip>
        ) : (
          this.renderButton()
        )}
      </div>
    );
  }
}

export default Button;
