import React from "react";
import { PropTypes } from "prop-types";
import { Cell, KeyIndicator } from "@wingmate/graphs";
import { AnalyticsHandshakeIcon } from "@wingmate/graphics";

export function ClosedWonCell({ closed }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const displayValue = closed?.current || 0;
  const previousValue = closed?.previous || 0;

  let trendPercentage = 0;
  if (previousValue > 0) {
    trendPercentage = (displayValue / previousValue - 1) * 100;
  }

  return (
    <Cell
      rowspan={1}
      colspan={1}
      icon={<AnalyticsHandshakeIcon width={44} height={44} />}
      color="#DAF3FE"
    >
      <KeyIndicator
        title="Closed Won"
        value={formatter.format(displayValue)}
        trendValue={formatter.format(previousValue)}
        trendPercentage={trendPercentage}
        trendLabel="Previous"
      />
    </Cell>
  );
}

ClosedWonCell.propTypes = {
  closed: PropTypes.shape({
    current: PropTypes.string,
    previous: PropTypes.string,
  }),
};

export default ClosedWonCell;
