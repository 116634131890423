import React from "react";
import PropTypes from "prop-types";
import { Cell } from "@wingmate/graphs";
import { Flex } from "@wingmate/toolkit";
import { GroupBreakdown } from "./GroupBreakdown";

import "./EngagementCell.scss";

export function EngagementCell({ engagement, onGroupClick }) {
  return (
    <Cell
      className="EngagementCell"
      rowspan={2}
      colspan={5}
      position={{ col: 1, row: 2 }}
      title="Engagement"
    >
      <Flex className="EngagementCell__Content" justify="space-around" gap={32}>
        {engagement &&
          engagement.map((group) => (
            <GroupBreakdown
              group={group}
              onClick={onGroupClick}
              key={group.id}
            />
          ))}
      </Flex>
    </Cell>
  );
}

EngagementCell.propTypes = {
  engagement: PropTypes.array,
  onGroupClick: PropTypes.func,
};

export default EngagementCell;
