import React from "react";
import { PropTypes } from "prop-types";
import { Cell, Leaderboard } from "@wingmate/graphs";

import "./LeaderboardCell.scss";

export function LeaderboardCell({ leaderboard = [] }) {
  return (
    <Cell
      className="LeaderboardCell"
      rowspan={3}
      colspan={1}
      position={{ col: 6, row: 1 }}
      title="Leaderboard"
    >
      <Leaderboard
        measurementLabel="Leads Generated"
        entryLabel="Generators"
        data={leaderboard}
      />
    </Cell>
  );
}

LeaderboardCell.propTypes = {
  leaderboard: PropTypes.arrayOf({
    name: PropTypes.string,
    imageSrc: PropTypes.string,
    value: PropTypes.number,
  }),
};

export default LeaderboardCell;
