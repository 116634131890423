import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./GlobalBanner.scss";
import { Icon } from "../Icon/Icon";

class GlobalBanner extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    text: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    text: "",
  };

  onCloseClick = () => {
    const { onClose } = this.props;

    onClose();
  };

  render() {
    const { className, text } = this.props;

    const bannerClass = classNames("alert-banner", className);
    return (
      <div className={bannerClass}>
        <div className="banner-body">{text}</div>
        <Icon
          onClick={this.onCloseClick}
          iconName="times"
          className="clear-button right"
        />
      </div>
    );
  }
}

export default GlobalBanner;
