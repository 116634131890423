import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Cell, PieChart, Legend } from "@wingmate/graphs";
import { Text, Drawer } from "@wingmate/toolkit";
import { AnalyticsDollarIcon } from "@wingmate/graphics";
import { Spinner } from "../Filters/Spinner";
import { makeFormattedRequest } from "../../../utils/url";
import { LeadListItem } from "./LeadListItem";

import "./TopRowPieChartCell.scss";

function formatCurrencyLegend(amount) {
  // Ensure the input is a valid number
  if (Number.isNaN(amount)) {
    throw new Error("Input must be a valid number");
  }

  // Format the number as currency
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

export function ClosedWonAndClosedLostCell({ closed, failed, filters }) {
  const [drawerData, setDrawerData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");

  const closedWon = parseFloat(closed?.current || 0);
  const closedLost = parseFloat(failed?.current || 0);

  const pieData = [
    {
      id: "Closed Won",
      label: "Closed Won",
      value: closedWon,
      color: "rgb(55,217,91)",
    },
    {
      id: "Closed Lost",
      label: "Closed Lost",
      value: closedLost,
      color: "rgb(196,69,54)",
    },
  ];

  const legendData = [
    {
      id: "Closed Won",
      label: "Closed Won",
      value: formatCurrencyLegend(closedWon),
      color: "rgb(55,217,91)",
    },
    {
      id: "Closed Lost",
      label: "Closed Lost",
      value: formatCurrencyLegend(closedLost),
      color: "rgb(196,69,54)",
    },
  ];

  const getDrawerData = async (params) => {
    setIsLoading(true);
    const response = await makeFormattedRequest(
      `crushr/analytics/lead_details.json?`,
      params
    );
    setDrawerData(response.data);
    setIsLoading(false);
  };

  const openDrawer = (e) => {
    let status = "";
    if (e.id === "Closed Won") {
      setDrawerTitle("Closed Won");
      status = "completed";
    } else {
      setDrawerTitle("Closed Lost");
      status = "failed";
    }
    const filtersForRequest = filters;
    filtersForRequest.status = status;
    getDrawerData(filtersForRequest);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    window.history.back();
  };

  return (
    <Cell
      className="TopRowPieChartCell"
      title="Won vs. Lost"
      rowspan={1}
      colspan={1}
      color="#D0EABE"
      icon={<AnalyticsDollarIcon width={44} height={44} />}
    >
      {pieData && (
        <>
          <PieChart
            width="320px"
            height="213px"
            data={pieData}
            onClick={(e) => {
              openDrawer(e);
            }}
            valueFormat=">-$,"
          />
          <Legend gap={4} entries={legendData} />
          <Drawer open={isDrawerOpen} onClose={closeDrawer}>
            <Text type="H2">{drawerTitle}</Text>
            <div className="TopRowPieChartCell__DrawerDetails">
              {isLoading ? (
                <div className="DrawerDetails__Spinner">
                  <Spinner />
                </div>
              ) : null}

              {drawerData.length && !isLoading ? (
                <ul>
                  {drawerData.map((lead) => (
                    <LeadListItem
                      key={lead.id}
                      id={lead.id}
                      name={lead.name}
                      city={lead.city}
                      street={lead.address}
                      date={lead.actioned_at}
                      health={lead.health}
                      imgSrc={lead.image_url || lead.thumbnail}
                      owners={lead.owners}
                      origin={lead.origin_url}
                    />
                  ))}
                </ul>
              ) : (
                <p>No data available</p>
              )}
            </div>
          </Drawer>
        </>
      )}
    </Cell>
  );
}

ClosedWonAndClosedLostCell.propTypes = {
  closed: PropTypes.shape({
    current: PropTypes.string,
    previous: PropTypes.string,
  }),
  failed: PropTypes.shape({
    current: PropTypes.string,
    previous: PropTypes.string,
  }),
  filters: PropTypes.object,
};

export default ClosedWonAndClosedLostCell;
