import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Icon.scss";

export class Icon extends React.Component {
  static defaultProps = {
    className: "",
    style: {},
  };

  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    iconName: PropTypes.string.isRequired,
    style: PropTypes.object,
  };

  onIconClick = (event) => {
    const { onClick } = this.props;

    if (onClick) {
      return onClick(event);
    }

    return event;
  };

  render() {
    const { className, iconName, onClick, style } = this.props;

    const fieldClassName = classNames(
      "icon",
      "fa",
      `fa-${iconName}`,
      className,
      {
        clickable: onClick !== undefined,
      }
    );

    return (
      <i
        aria-label={iconName}
        role="button"
        tabIndex={0}
        className={fieldClassName}
        onClick={this.onIconClick.bind(this)}
        onKeyUp={this.onIconClick.bind(this)}
        style={style}
      />
    );
  }
}

export default Icon;
