import React from "react";
import PropTypes from "prop-types";
import { Text, Flex } from "@wingmate/toolkit";
import { Cell } from "@wingmate/graphs";
import { TerminalTable } from "./TerminalTable";

export function TerminalCell({ terminal }) {
  return (
    <Cell
      className="TerminalCell"
      rowspan={3}
      colspan={1}
      position={{ col: 6, row: 1 }}
      title="Terminals"
    >
      {terminal && (
        <Flex gap={16} vertical>
          <Flex className="TerminalCell__List" align="center" vertical>
            <Text type="H4">Top 10 Terminals</Text>
            <TerminalTable terminals={terminal.top} />
          </Flex>
          <Flex className="TerminalCell__List" align="center" vertical>
            <Text type="H4">Bottom 10 Terminals</Text>
            <TerminalTable terminals={terminal.bottom} />
          </Flex>
        </Flex>
      )}
    </Cell>
  );
}

TerminalCell.propTypes = {
  terminal: PropTypes.shape({
    bottom: PropTypes.arrayOf(PropTypes.object),
    top: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default TerminalCell;
