import React from "react";
import PropTypes from "prop-types";
import { Button, Flex, Text } from "@wingmate/toolkit";
import { LeaderboardPodium, PieChart } from "@wingmate/graphs";

import "./GroupBreakdown.scss";

export function GroupBreakdown({ group, onClick }) {
  const [first, second, third] = group.leaders;

  const onNameClick = () => {
    onClick(group);
  };

  return (
    <Flex align="center" className="GroupBreakdown" vertical>
      <Button
        className="GroupBreakdownDrillDown"
        onClick={onNameClick}
        type="outline"
      >
        <Text type="H3">{group.name}</Text>
      </Button>
      <Flex gap={4}>
        <Flex gap={2}>
          <Text type="H6" weight="Semi">
            Leads:
          </Text>
          <Text type="H6">{group.leads}</Text>
        </Flex>
        <Text type="H6">|</Text>
        <Flex gap={2}>
          <Text type="H6" weight="Semi">
            Sign Ups:
          </Text>
          <Text type="H6">{group.signups}</Text>
        </Flex>
      </Flex>
      <PieChart
        width="350px"
        height="170px"
        margin={{ left: 20, top: 20, bottom: 20, right: 20 }}
        data={group.subgroups}
        showLinkLabels
      />
      <LeaderboardPodium first={first} second={second} third={third} />
    </Flex>
  );
}

GroupBreakdown.propTypes = {
  onClick: PropTypes.func,
  group: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    signups: PropTypes.number,
    leads: PropTypes.number,
    leaders: PropTypes.array,
    subgroups: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.number,
        signups: PropTypes.number,
        leads: PropTypes.number,
        value: PropTypes.number,
      })
    ),
  }),
};

export default GroupBreakdown;
