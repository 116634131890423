import React from "react";
import { PropTypes } from "prop-types";
import { Cell, PieChart, Legend } from "@wingmate/graphs";
import { AnalyticsFunnelIcon } from "@wingmate/graphics";
import "./TopRowPieChartCell.scss";

export function TimeInEachStage({ stages }) {
  if (!stages || !stages.data || !stages.stage_names) {
    return (
      <Cell
        title="Time in Each Stage"
        rowspan={1}
        colspan={1}
        position={{ col: 3, row: 1 }}
      >
        <p>No data available</p>
      </Cell>
    );
  }

  function getColor(index) {
    const colors = [
      "rgb(255,196,0)",
      "rgb(255,141,48)",
      "rgb(202,149,255)",
      "rgb(0,153,247)",
      "rgb(123,97,255)",
      "rgb(44,73,113)",
    ];
    return colors[index % colors.length];
  }

  const pieData = stages.data.map((value, index) => ({
    id: stages.stage_names[index],
    label: stages.stage_names[index],
    value,
    color: getColor(index),
  }));

  return (
    <Cell
      title="Avg Days in Stage"
      rowspan={1}
      colspan={1}
      position={{ col: 3, row: 1 }}
      className="TopRowPieChartCell"
      color="rgba(255, 129, 94, .3)"
      icon={<AnalyticsFunnelIcon width="36" height="36" />}
    >
      <>
        <PieChart width="320px" height="213px" data={pieData} />
        <Legend gap={4} entries={pieData} />
      </>
    </Cell>
  );
}

TimeInEachStage.propTypes = {
  stages: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.number),
    stage_names: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default TimeInEachStage;
