import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";

import "./TerminalTable.scss";

export function TerminalTable({ terminals }) {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Leads",
      dataIndex: "leads",
      key: "leads",
    },
    {
      title: "Sign Ups",
      dataIndex: "signups",
      key: "signups",
    },
  ];

  const dataSource = terminals.map((terminal) => ({
    ...terminal,
    key: terminal.id,
  }));

  return (
    <Table
      className="TerminalTable"
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{ y: 270 }}
    />
  );
}

TerminalTable.propTypes = {
  terminals: PropTypes.arrayOf(PropTypes.object),
};

export default TerminalTable;
