import React from "react";
import PropTypes from "prop-types";
import { Cell, PieChart, Legend } from "@wingmate/graphs";
import { Flex } from "@wingmate/toolkit";

import "./StatusCell.scss";

export function StatusCell({ status }) {
  return (
    <Cell
      className="StatusCell"
      title="Leads by Status"
      rowspan={1}
      colspan={1}
      position={{ col: 3, row: 2 }}
    >
      {status !== undefined && (
        <Flex gap={8} vertical>
          <PieChart
            width="320px"
            height="213px"
            data={status}
            margin={{ left: 8, top: 8, bottom: 8, right: 8 }}
          />
          <Legend gap={8} entries={status} />
        </Flex>
      )}
    </Cell>
  );
}

StatusCell.propTypes = {
  status: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
      tooltip: PropTypes.string,
    })
  ),
};

export default StatusCell;
