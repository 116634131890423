import React from "react";
import PropTypes from "prop-types";
import { ProgressBar, Row } from "wm-ui-toolkit";
import "./LeadsChasedMetricBar.scss";

export class LeadsChasedMetricBar extends React.Component {
  static propTypes = {
    leadsHot: PropTypes.number,
    leadsQualified: PropTypes.number,
    leadsChased: PropTypes.number,
    leadsPurchased: PropTypes.number,
    leadsAvailable: PropTypes.number,
  };

  render() {
    const {
      leadsAvailable,
      leadsChased,
      leadsHot,
      leadsPurchased,
      leadsQualified,
    } = this.props;

    const hotLeadPercent = Number.parseInt(
      (leadsHot / leadsPurchased) * 100,
      10
    );

    const qualifiedLeadPercent = Number.parseInt(
      (leadsQualified / leadsPurchased) * 100,
      10
    );

    const chasedLeadPercent = Number.parseInt(
      (leadsChased / leadsPurchased) * 100,
      10
    );
    const disabledPercent = Number.parseInt(
      (1 - leadsAvailable / leadsPurchased) * 100,
      10
    );

    return (
      <Row className="LeadsChasedMetricBar" alignItems="center">
        <ProgressBar
          percentages={[
            {
              percent: hotLeadPercent,
              color: "#FE3E59",
              tooltip: `${leadsHot}/${leadsPurchased} leads hot`,
            },
            {
              percent: qualifiedLeadPercent,
              color: "#187204",
              tooltip: `${leadsQualified}/${leadsPurchased} leads qualified`,
            },
            {
              percent: chasedLeadPercent,
              color: "#7ECEFD",
              tooltip: `${leadsChased}/${leadsPurchased} leads chased`,
            },
          ]}
          disabledPercentage={{
            percent: disabledPercent,
            tooltip: `${leadsAvailable}/${leadsPurchased} available leads to chase`,
          }}
        />
      </Row>
    );
  }
}

export default LeadsChasedMetricBar;
