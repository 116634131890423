import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dashboard } from "@wingmate/graphs";
import { axiosGet } from "../../requests/axiosRequests";
import {
  encodeDictionaryToUrlParams,
  removeUndefinedValues,
} from "../../utils/commons";
import { urlEncodeParams } from "../../utils/url";
import { StatusCell } from "./StatusCell";
import { LeaderboardCell } from "./LeaderboardCell";
import { LeadsGeneratedCell } from "./KPIs/LeadsGeneratedCell";
import { Filters } from "./Filters";
import { TerminalCell } from "./TerminalCell/TerminalCell";
import { ClosedWonCell } from "./KPIs/ClosedWonCell";
import { RewardCell } from "./KPIs/RewardCell";
import { SignUpCell } from "./KPIs/SignUpCell";
import { EngagementCell } from "./EngagementCell/EngagementCell";
import purolatorLogo from "../../assets/purolator.jpeg";

import "./EngagementDashboard.scss";

const SINGLE_TERMINAL_SECTION_IDS = [
  "leads",
  "signups",
  "closed",
  "leaderboard",
  "rewards",
  "status",
  "engagement",
];

const DEFAULT_SECTION_IDS = [
  "leads",
  "signups",
  "closed",
  "terminal",
  "rewards",
  "status",
  "engagement",
];

const ANALYTICS_ENDPOINT = (sectionId, filters) =>
  `purolator/analytics/${sectionId}.json?${filters}`;

export function EngagementDashboard({ campaigns, defaultFilters, groups }) {
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    interval: "week",
    startDate: undefined,
    endDate: undefined,
    ...defaultFilters,
  });

  const [analytics, setAnalytics] = useState({});

  const setBrowserUrl = (params) => {
    if (window.history.replaceState) {
      const encodedParams = urlEncodeParams(params);

      const newUrl = `${window.location.pathname}?${encodedParams}`;
      window.history.pushState("", null, newUrl);
    }
  };

  const getSelectedGroups = () => {
    if (filters.groupIds !== null && filters.groupIds !== undefined) {
      return groups.filter((group) =>
        filters.groupIds.includes(group.id.toString())
      );
    }
    return [];
  };

  const viewingSingleTerminal = () => {
    const selectedGroups = getSelectedGroups();

    return (
      selectedGroups.length === 1 &&
      selectedGroups[0].attributes.tag === "terminal"
    );
  };

  const getSectionIds = () => {
    if (viewingSingleTerminal()) {
      return SINGLE_TERMINAL_SECTION_IDS;
    }
    return DEFAULT_SECTION_IDS;
  };

  const getAnalytics = async (params) => {
    setIsLoading(true);

    const cleanedParams = removeUndefinedValues(params);

    setBrowserUrl(cleanedParams);

    const payload = {
      interval: params.interval,
      group_ids: params.groupIds,
      campaign_ids: params.campaignIds,
      start_date: params.startDate,
      end_date: params.endDate,
    };

    const cleanedPayload = removeUndefinedValues(payload);

    const newAnalytics = { ...analytics };

    await Promise.all(
      getSectionIds().map(async (sectionId) => {
        const response = await axiosGet(
          ANALYTICS_ENDPOINT(
            sectionId,
            encodeDictionaryToUrlParams(cleanedPayload)
          )
        );

        newAnalytics[sectionId] = response.data[sectionId];

        setAnalytics(newAnalytics);
      })
    );

    setIsLoading(false);
  };

  const onFilterChange = (changedFilters) => {
    const newFilters = {
      ...filters,
      ...changedFilters,
    };

    setFilters(newFilters);

    getAnalytics(newFilters);
  };

  const onGroupClick = (group) => {
    onFilterChange({ groupIds: [group.id.toString()] });
  };

  useEffect(() => {
    getAnalytics(filters);
  }, []);

  const renderTerminalLeaderboardSpot = () => {
    if (viewingSingleTerminal()) {
      return <LeaderboardCell leaderboard={analytics.leaderboard} />;
    }
    return <TerminalCell terminal={analytics.terminal} />;
  };

  return (
    <Dashboard
      title="Purolator"
      className="EngagementDashboard"
      subtitle="Engagement"
      iconColor="#FFFFFF"
      columnCount={6}
      rowCount={3}
      icon={<img src={purolatorLogo} alt="Purolator" />}
      filters={
        <Filters
          campaigns={campaigns}
          groups={groups}
          defaultFilters={filters}
          isLoading={isLoading}
          onSubmit={onFilterChange}
        />
      }
    >
      <SignUpCell signups={analytics.signups} />
      <LeadsGeneratedCell leads={analytics.leads} />
      <ClosedWonCell closed={analytics.closed} />
      <RewardCell rewards={analytics.rewards} />
      <StatusCell status={analytics.status} />
      {renderTerminalLeaderboardSpot()}
      <EngagementCell
        onGroupClick={onGroupClick}
        engagement={analytics.engagement}
      />
    </Dashboard>
  );
}

EngagementDashboard.propTypes = {
  campaigns: PropTypes.array,
  defaultFilters: PropTypes.object,
  groups: PropTypes.array,
};

export default EngagementDashboard;
