import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { SankeyChart, Cell } from "@wingmate/graphs";
import { Text, Drawer } from "@wingmate/toolkit";
import { AnalyticsHandshakeIcon } from "@wingmate/graphics";
import { Spinner } from "../Filters/Spinner";
import "./StatusChangeCell.scss";
import { makeFormattedRequest } from "../../../utils/url";
import { LeadGroup } from "./LeadGroup";

export function StatusChangeCell({ changes, filters }) {
  const [drawerData, setDrawerData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const groupLeads = (leads) => {
    const groupedMap = leads.reduce((groupedLeads, currentLead) => {
      const previousStatus = currentLead.previous_status;
      if (!groupedLeads[previousStatus]) {
        groupedLeads[previousStatus] = [];
      }
      groupedLeads[previousStatus].push(currentLead);
      return groupedLeads;
    }, {});
    return Object.values(groupedMap);
  };

  const getDrawerData = async (params) => {
    setIsLoading(true);

    try {
      const response = await makeFormattedRequest(
        `crushr/analytics/lead_details.json?`,
        params
      );

      const groupedLeads = groupLeads(response.data);
      setDrawerData(groupedLeads);
    } catch (error) {
      throw new Error(error.message);
    }
    setIsLoading(false);
  };

  const showData = (target) => {
    if (target.source) return;

    const filtersForRequest = filters;
    filtersForRequest.status = target.status;
    getDrawerData(filtersForRequest);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    window.history.back();
  };

  return (
    <>
      <Cell
        rowspan={1}
        colspan={2}
        position={{ col: 1, row: 2 }}
        icon={<AnalyticsHandshakeIcon width={44} height={44} />}
        color="#DAF3FE"
      >
        <Text
          type="P"
          weight="Medium"
          className="KeyIndicator__Title AnalyticsTitle"
        >
          Status Changes
        </Text>
        <br />
        <br />
        {changes && (
          <SankeyChart
            data={changes}
            height={300}
            onClick={(clickedNode) => showData(clickedNode)}
          />
        )}
      </Cell>
      <Drawer open={isDrawerOpen} onClose={closeDrawer}>
        <Text type="H2">Status Changes</Text>
        <div className="Drawer__Details">
          {isLoading ? (
            <div className="Drawer__Details__Spinner">
              <Spinner />
            </div>
          ) : (
            drawerData.length &&
            drawerData.map((leadGroup) => {
              return (
                <LeadGroup
                  data={leadGroup}
                  labels={changes.nodes}
                  key={leadGroup[0].status}
                />
              );
            })
          )}
        </div>
      </Drawer>
    </>
  );
}

StatusChangeCell.propTypes = {
  changes: PropTypes.object,
  filters: PropTypes.object,
};

export default StatusChangeCell;
