import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Dropdown, Flex, DateRangeField, Text } from "@wingmate/toolkit";

import "./DateFilter.scss";

const WEEK_TYPE = "week";
const MONTH_TYPE = "month";
const QUARTER_TYPE = "quarter";
const YEAR_TYPE = "year";
const CUSTOM_TYPE = "custom";

const LABELS = {
  week: "This Week",
  month: "This Month",
  quarter: "This Quarter",
  year: "This Year",
  custom: "Custom",
};

export function DateFilter({
  defaultEndDate,
  defaultInterval,
  defaultStartDate,
  onChange,
}) {
  const getDefaultDate = (dateValue) => {
    if (dateValue) {
      return moment(dateValue).toDate();
    }

    return undefined;
  };

  const [selectedFilter, setSelectedFilter] = useState(defaultInterval);
  const [startDate, setStartDate] = useState(getDefaultDate(defaultStartDate));
  const [endDate, setEndDate] = useState(getDefaultDate(defaultEndDate));
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const clearOnChange = (interval) => {
    onChange({
      interval,
      startDate: undefined,
      endDate: undefined,
    });
  };

  const onClick = ({ key }) => {
    if (key !== CUSTOM_TYPE) {
      setDropdownOpen(false);
      setSelectedFilter(key);
      setStartDate(undefined);
      setEndDate(undefined);
      clearOnChange(key);
    }
  };

  const toggleDropdownOpen = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getFilterLabel = () => {
    if (selectedFilter === CUSTOM_TYPE) {
      return `${moment(startDate).format("DD-MM-YYYY")} - ${moment(
        endDate
      ).format("DD-MM-YYYY")}`;
    }

    return LABELS[selectedFilter];
  };

  const onCustomChange = (values) => {
    setSelectedFilter(CUSTOM_TYPE);
    setDropdownOpen(false);

    setStartDate(values.startDate);
    setEndDate(values.endDate);

    if (!values.startDate && !values.endDate) {
      setSelectedFilter("week");
      clearOnChange("week");
    } else {
      onChange({
        interval: CUSTOM_TYPE,
        ...values,
      });
    }
  };

  const items = [
    {
      key: WEEK_TYPE,
      label: <Text type="P">This Week</Text>,
    },
    {
      key: MONTH_TYPE,
      label: <Text type="P">This Month</Text>,
    },
    {
      key: QUARTER_TYPE,
      label: <Text type="P">This Quarter</Text>,
    },
    {
      key: YEAR_TYPE,
      label: <Text type="P">This Year</Text>,
    },
    {
      key: CUSTOM_TYPE,
      label: (
        <DateRangeField
          startId="startDate"
          endId="endDate"
          placement="bottomLeft"
          onChange={onCustomChange}
        />
      ),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
        onClick,
      }}
      trigger="click"
      placement="bottomLeft"
      open={dropdownOpen}
      className="DateFilterDropdown"
      buttonProps={{
        onClick: toggleDropdownOpen,
        type: "filled",
        secondary: true,
      }}
    >
      <Flex justify="space-between" align="center">
        {getFilterLabel()}
      </Flex>
    </Dropdown>
  );
}

DateFilter.propTypes = {
  defaultInterval: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  onChange: PropTypes.func,
};

export default DateFilter;
