import React from "react";
import { Text, Tag, AvatarGroup, Avatar, Flex, Image } from "@wingmate/toolkit";
import PropTypes from "prop-types";
import { HeartbeatIcon, PinIcon } from "@wingmate/graphics";
import {
  formatDateByMonthDayYear,
  truncateString,
} from "../../../utils/commons";
import "./LeadListItem.scss";

export function LeadListItem({
  address,
  city,
  date,
  health,
  id,
  imgSrc,
  key,
  name = "",
  origin,
  owners = [],
  status,
  statusColor,
}) {
  const monthDay = formatDateByMonthDayYear(date).slice(0, 5);
  const combinedAddress =
    city && address ? `${address}, ${city}` : `${address}`;
  const nameCatch = name || `Lead #${id}`;
  const formattedName =
    owners.length > 1
      ? truncateString(nameCatch, 24)
      : truncateString(nameCatch, 30);

  const leadUrl = `${origin}/leads/${id}`;
  const goToLeadPage = () => {
    window.open(leadUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <Flex key={key} component="li" className="ListItem" onClick={goToLeadPage}>
      <Image className="ListItem__Image" src={imgSrc} alt={`${name} lead`} />
      <Flex vertical className="ListItem__Info__Container">
        <Flex className="ListItem__Info">
          <div>
            <Text weight="Semi" type="P">
              {formattedName}
            </Text>
            {city ? (
              <Text>
                <PinIcon
                  className="ListItem__Info__Pin"
                  height="16px"
                  width="10px"
                />
                {owners.length === 0 && truncateString(combinedAddress, 30)}
                {owners.length === 1 && truncateString(combinedAddress, 27)}
                {owners.length >= 2 && truncateString(combinedAddress, 23)}
              </Text>
            ) : null}
          </div>
          <AvatarGroup>
            {owners.map((owner) => {
              return (
                <Avatar name={owner.full_name} src={owner.profile_image_url} />
              );
            })}
          </AvatarGroup>
        </Flex>
        <Flex className="ListItem__Stats">
          {status && <Tag color={statusColor}>{status}</Tag>}
          <span className="ListItem__Stats__Month">
            {monthDay}
            <span className="ListItem__Stats__Pipe"> | </span>
            <HeartbeatIcon
              className={health}
              fill={health}
              height="16"
              width="16"
            />
          </span>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LeadListItem;

LeadListItem.propTypes = {
  city: PropTypes.string,
  date: PropTypes.string,
  health: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  origin: PropTypes.string,
  owners: PropTypes.arrayOf(
    PropTypes.shape({
      full_name: PropTypes.string,
      profile_image_url: PropTypes.string,
    })
  ),
  key: PropTypes.string,
  status: PropTypes.string,
  address: PropTypes.string,
  statusColor: PropTypes.string,
};
